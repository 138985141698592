import React, { Component } from 'react';
import { withFirebase } from '../components/Firebase/FirebaseContext';
import { withTrainings } from '../BuisenessLogic/TrainingsContext';
import Layout from '../components/layout';
import Rental from '../components/rental';

class Contacts extends Component {
  render() {
    return <Rental lang="eng" />;
  }
}

const LandingPageWithFirebase = withFirebase(withTrainings(Contacts));

export default () => (
  <Layout
    title={'Road bicycle rental in Kiev'}
    description={'Good quality road bicycle in ready to race conditions'}
    script={<script src="//code.jivosite.com/widget.js" data-jv-id="oVZl7ZwUKO" async />}
  >
    <LandingPageWithFirebase />
  </Layout>
);
