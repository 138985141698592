import React from 'react';
import { withPrefix, Link } from 'gatsby';
import ImageGallery from 'react-image-gallery';
import { FaFacebook, FaPhone } from 'react-icons/fa';
import 'react-image-gallery/styles/css/image-gallery.css';
import '../css/sigma.scss';
import styled from 'styled-components';

const Wrapper = styled.div`
  * {
    color: white;
  }
`;

const lib = {
  eng: {
    title: 'Bicycle rental',
    desc: (
      <p style={{ fontSize: 15 }}>
        Our team provide<b> bicycles rental service in Kiev </b> to the team members and independent
        athletes. <br /> Now we could offer two model of road bicycles. <b>Giant Contend 3</b> and{' '}
        <b>SE Racing-Royal</b> both in medium size (54 cm). <br />
        <br />
        Bicycles are in perfect, 'ready to race' condition and could be provided with Shimano
        contact pedals or with regular pedals by request. Could be delivered and picked back by
        request to one of our{' '}
        <Link style={{ color: 'white' }} to="/trainings">
          trainings.
        </Link>{' '}
        <br /> <br />
        Також доступна{' '}
        <Link to="/прокат" style={{ color: 'white' }}>
          українська версія цієї сторінки
        </Link>
        .
      </p>
    ),
    price: 'Price: 500 uah per day. Minimum 2 days'
  },
  rus: {
    title: 'Прокат велосипедів',
    desc: (
      <p style={{ fontSize: 15 }}>
        Наша команда пропонує <b>прокат шосейних велосипедів у Києві</b> для членів команди, а також
        для індивідуальних спортсменів. У нашому арсеналі дві моделі <b>Giant Contend 3</b> та{' '}
        <b>SE Racing-Royal</b>. Обидва середнього розміру (54 cm).
        <br />
        <br />
        Велосипеди в чудовому стані, готові для гонки або тривалого{' '}
        <Link style={{ color: 'white' }} to="/trainings">
          тренування
        </Link>
        . Можуть бути укомплектовані контактними педалями Shimano або звичайними педалями.
        <br />
        <br />
        Also we provide english{' '}
        <Link to="/bicycle-rental" style={{ color: 'white' }}>
          version of this page.
        </Link>
      </p>
    ),
    price: 'Ціна: 500 грн за день. Мінімальний термін оренди: 2 дні'
  }
};
const Rental = ({ lang }) => {
  const text = lib[lang];
  const images = [
    {
      original: withPrefix('Contend 3_Black_Orange_2000.jpg'),
      thumbnail: withPrefix('Contend 3_Black_Orange_2000.jpg'),
      description: 'Giant Contend 3'
    },
    {
      original: withPrefix('SE-bikes-ROYAL.png'),
      thumbnail: withPrefix('SE-bikes-ROYAL.png'),
      description: 'SE Racing-Royal'
    },
    {
      original: withPrefix('59163417_461160271289464_468697055024381952_n.jpg'),
      thumbnail: withPrefix('59163417_461160271289464_468697055024381952_n.jpg'),
      description: 'Giant Contend 3'
    },
    {
      original: withPrefix('59192841_876509786021625_5016942047098044416_n.jpg'),
      thumbnail: withPrefix('59192841_876509786021625_5016942047098044416_n.jpg'),
      description: 'Giant Contend 3'
    },
    {
      original: withPrefix('59740290_481991872337830_9137257268260110336_n.jpg'),
      thumbnail: withPrefix('59192841_876509786021625_5016942047098044416_n.jpg'),
      description: 'SE Racing-Royal'
    }
  ];
  return (
    <Wrapper className={'container'}>
      <h2 className={'text-center'}>{text.title}</h2>
      {text.desc}
      <h6>{text.price}</h6>

      <FaPhone />
      <a style={{ color: 'white' }} href="tel:+380509276677">
        {' '}
        0509276677
      </a>
      <br />
      <FaFacebook />
      <a style={{ color: 'white' }} href="https://www.facebook.com/volovodroadtraining/">
        {' '}
        Facebook
      </a>

      <div>
        <ImageGallery showPlayButton={false} items={images} />
      </div>
    </Wrapper>
  );
};

export default Rental;
